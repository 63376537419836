<template>
    <div class="wrapper">
        <div class="message-box" v-if="message && !showSuccessMessage">
            <b-alert show variant="danger">
                <h4 class="alert-heading">unsuccessful!</h4>
                <p>{{$t('api.' + message)}}</p>
            </b-alert>
        </div>
        <div class="message-box" v-if="!message && showSuccessMessage">
            <b-alert show variant="success">
                <h4 class="alert-heading">successfully!</h4>
                <p>Your request has been submitted. To complete, you need to pay the Airport Pick-Up fee via this <router-link :to="`/epayment/housing/${$route.params.token}`">link</router-link></p>
            </b-alert>
        </div>
        <CommonModal size="lg" ref="housingAgreementForm" :closeBtn="false" :noCloseOnBackDrop="true">
            <template v-slot:CommonModalTitle>
                <div>{{ $t("airport_transfer_request").toUpperCase() }}</div>
            </template>
            <template v-slot:CommonModalContent>
              <AirportAgreementForm :formData="formData"  @successFormSave="successFormSave"/>
            </template>
        </CommonModal>
    </div>
</template>
<script>
import HousingReservationAgreementService from "@/services/HousingReservationAgreementService";
import AirportAgreementForm from "@/modules/HousingAirportTransfer/pages/AirportAgreementForm.vue";
export default {
    components: {
        AirportAgreementForm
    },
    metaInfo() {
        return {
            title: this.$t('housing_airport_transfer_form')
        }
    },
    data() {
        return {
            message:null,
            formProcess: null,
            formData:{
                fee:null
            },
            showSuccessMessage:false
        }
    },
    methods: {
       async isReservation(){
           try {
               const res = await HousingReservationAgreementService.checkToken(this.$route.params.token)
               this.formData.fee=res.data.data.airport_pick_up_fee
               if (res.data.success){
                   this.openModal()
               }
           }catch (error) {
               this.message=error.data.message
               this.showErrors(error)
           }
        },

       async openModal(){
            this.formProcess = 'airport-agreement-form';
            this.$refs.housingAgreementForm.$refs.commonModal.show()
        },
        successFormSave(){
            this.message=null
            this.showSuccessMessage=true
            this.timer()
            this.$refs.housingAgreementForm.$refs.commonModal.hide()

        },
        timer(){
           setTimeout(() => {
               this.$router.push('/epayment/housing/' + this.$route.params.token)
           },5000)
        }
    },
    created() {
        this.isReservation()
    },
};
</script>
<style scoped>
.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    border: 1px solid #ddd;
    position: relative;
}


.message-box {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
</style>


